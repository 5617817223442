<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button type="primary" size="mini" @click="handleAdd" v-has="'create_company'">添加
                </el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="单位管理"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small" label-width="100px">
                <el-form-item label="单位名称" >
                    <el-input placeholder="请输入内容" v-model="search.name"  clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" v-has="'company_check'">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" stripe size="mini" v-loading="loading" width="100%" height="100%"
                          row-key="id"
                          :lazy="true"
                          :load="load"
                          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                >
                    <el-table-column label="单位"  prop="name" width="400">
                    </el-table-column>
                    <el-table-column align="center" label="单位等级">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.level==1">一级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==2">二级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==3 && scope.row.entOrgNameTwo == '公司机关'">超三级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==3 ">三级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==4 && scope.row.entOrgNameTwo == '公司机关'">超四级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==4">四级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==5 && scope.row.entOrgNameTwo == '公司机关'">超五级单位</el-tag>
                            <el-tag v-else-if="scope.row.level==5">五级单位</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="单位总人数"  prop="companyCount">
                    </el-table-column>
                    <el-table-column align="center" label="单位信息" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>{{ scope.row.address }}</div>
                            <div>
                                <div >{{ scope.row.short_name }}</div>
                                <div >{{ scope.row.en_name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="单位负责人">
                        <template slot-scope="scope">
                            <div>{{ scope.row.pic_name }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="负责人电话">
                        <template slot-scope="scope">
                            <div>{{ scope.row.pic_concat }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="创建时间" >
                        <template slot-scope="scope">
                            <div>{{ scope.row.created_at || '' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" width="200" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleRowChange(scope.row)" v-has="'edit_company'">修改
                            </el-button>
                            <el-button type="text" size="mini" @click="handleAddCompany(scope.row)" v-has="'add_subordinate_company'">添加下级单位
                            </el-button>
                            <el-button type="text" size="mini" @click="handleDelete(scope.row)" v-has="'delete_company'" v-if="scope.row.level !='1'">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes"
                           :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
        <data-form :visible="dialogVisible" :form="form" @close="dialogVisible = false"
                   @update="getData" :name="name" :companyLevel="companyLevel"></data-form>
    </div>
</template>

<script>
    import data from './data'
    import formatter from "../../../data/formatter"
    import dataForm from "./widgets/dataform";

    export default {
        name: 'account',
        components: {
            dataForm,
        },
        data() {
            return {
                form: {},
                dialogVisible: false,
                formatter: formatter,
                loading: false,
                search: {},
                table: data.table,
                name: null,
                companyLevel:null
            }
        },
        methods: {
            async load(tree, treeNode, resolve) {
                console.log(tree, treeNode, resolve)
                const resp = await this.$http.get(`/company/company/view/${tree.id}`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    resolve(resp.data.data)
                }
            },
            handleAddCompany(row){
                this.form = {}
                this.form.pid = row.id
                this.name = row.name
                this.companyLevel = row.level
                this.dialogVisible = true
            },
            handleDelete(row) {
                this.$confirm(`确认要删除 ${row.name} 的记录吗？`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$http.delete(`/company/company/${row.id}`)
                        .then(resp => {
                            this.$message[resp.data.status](resp.data.msg)
                            if (resp.data.status == 'success') this.getData()
                        }).catch(err => {
                        this.$message.error(err.message)
                    }).finally(() => {
                        this.loading = false
                    })
                    this.loading = false
                }).catch(() => {
                });
            },
            handleAdd() {
                this.form = {}
                this.name = ""
                this.dialogVisible = true
            },
            handleRowChange(row){
                this.form = JSON.parse(JSON.stringify(row))
                delete this.form.children
                this.dialogVisible = true
            },
            handlePageChange(e) {
                this.table.page = e
                this.getData()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            async getData() {
                this.dialogVisible = false
                this.loading = true
                const resp = await this.$http.get(`/company/company/load?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
                this.loading = false
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px 0 !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
